<template lang="pug">
v-breadcrumbs.ma-3(
    v-if="breadcrumbs.checkAccess()"
    :items="breadcrumbs.historyBreadcrumbs" large)

    template(v-slot:item="{ item }")
        v-breadcrumbs-item(
            @click="$router.push(item.link)"
            :disabled="item.disabled") {{ item.text }}
</template>
<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: { type: Object, default: () => ({}) }
  }
}
</script>
